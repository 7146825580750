import { CONFIG } from '@/features/slides_editor/config/constants/slides'

const getSlideType = (slide) => {
  if (slide.blocks.some(block => block.block_type === 'section')) {
    return 'section'
  }

  return 'default'
}


export function useSlideHelpers() {
  const getConfig = (slide) => {
    return CONFIG[getSlideType(slide)]
  }

  return {
    getConfig,
  }
}
