<template>
  <div
    class="h-full bg-white shadow-lg ease-in-out transition-all duration-300 side-panel relative"
    :class="[isOpen ? 'w-[384px]' : 'w-0']"
  >
    <div v-if="isOpen" class="p-4 relative">
      <button @click="closePanel" class="absolute top-0 right-0 text-gray-500 hover:text-gray-700 p-4">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>

      <h2 class="text-lg font-semibold mb-4">{{ panelTitle }}</h2>
      <component :is="currentComponent" v-bind="componentProps" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { SIDE_PANEL_COMPONENTS } from '../../components_directory'

export default {
  name: 'SidePanel',
  components: {
    ...SIDE_PANEL_COMPONENTS
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    },
    panelContent: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props, { emit }) {
    const panelTitle = computed(() => props.panelContent.title || 'Panel')
    const currentComponent = computed(() => props.panelContent.component || null)
    const componentProps = computed(() => props.panelContent.props || {})

    const closePanel = () => {
      if (props.fixed) {
        return
      }

      emit('close')
    }

    return {
      panelTitle,
      currentComponent,
      componentProps,
      closePanel
    }
  }
}
</script>
