<template>
  <div class="relative h-full" :class="{ 'w-fit m-auto': isYouTubeShorts || isTikTok }" @click="onEmbedClick" :data-editable="block.editable">
    <!-- YouTube Videos -->
    <YouTubeEmbed v-if="isYouTube" :block="block" />

    <!-- YouTube Shorts -->
    <YouTubeShortsEmbed v-if="isYouTubeShorts" :block="block" />

    <!-- TikTok Videos -->
    <div v-if="isTikTok">
      <div
        :class="`pointer-events-auto max-w-[${CONSTANTS.TIKTOK.MAX_WIDTH}] h-[${CONSTANTS.TIKTOK.HEIGHT}] overflow-hidden rounded-lg w-fit m-auto ${block.embed.embeddable == 'Slide' ? 'mt-10' : ''}`"
        data-provider="tiktok"
        v-html="block.embed.html"
      />
    </div>

    <!-- Other Embeds -->
    <div v-if="isOtherEmbed">
      <div class="flex flex-col items-center justify-center" data-provider="other">
        <iframe
          class="w-10/12 rounded-lg"
          id="ytplayer"
          type="text/html"
          :width="CONSTANTS.OTHER_EMBED.WIDTH"
          :height="CONSTANTS.OTHER_EMBED.HEIGHT"
          :src="block.embed.original_url"
          allow="fullscreen"
          frameborder="0">
        </iframe>
      </div>
    </div>

    <!-- Video Upload Overlay -->
    <VideoUploadOverlay
      v-if="block.editable"
      :width="overlayWidth"
      :height="overlayHeight"
      :clip-path="overlayClipPath"
    />
  </div>
</template>

<script>
import { toRef, inject, watch } from 'vue'
import VideoUploadOverlay from '../../embeds/VideoUploadOverlay.vue'
import YouTubeEmbed from '../../embeds/YouTubeEmbed.vue'
import YouTubeShortsEmbed from '../../embeds/YouTubeShortsEmbed.vue'
import { useEmbedHelpers } from '../../../composables/useEmbedHelpers'

export default {
  name: 'Embed',
  components: {
    VideoUploadOverlay,
    YouTubeEmbed,
    YouTubeShortsEmbed
  },
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const embedRef = toRef(props.block, 'embed')
    const openSidePanel = inject('openSidePanel')

    const onEmbedClick = () => {
      if (!props.block.editable) {
        return
      }

      openSidePanel({
        title: 'Video Settings',
        component: 'VideoForm',
        props: { block: props.block }
      })
    }

    const {
      CONSTANTS,
      isYouTube,
      isYouTubeShorts,
      isTikTok,
      isOtherEmbed,
      overlayWidth,
      overlayHeight,
      overlayClipPath,
      youtubeWrapperClass,
      youtubeStyle,
      youtubeVideoClass
    } = useEmbedHelpers(embedRef)

    const updateProvider = (embedRef, newUrl) => {
      const url = new URL(newUrl)
      const providers = [
        { key: 'youtube.com/shorts', value: 'youtube_shorts' },
        { key: 'youtube.com', value: 'youtube' },
        { key: 'tiktok', value: 'tiktok' }
      ]

      const match = providers.find(provider => newUrl.includes(provider.key))
      if (match) {
        embedRef.value.provider = match.value
      }
    }

    watch(
      () => [props.block.embed.original_url],
      ([newUrl], [oldUrl]) => {
        updateProvider(embedRef, newUrl)
      }
    )

    return {
      CONSTANTS,
      isYouTube,
      isYouTubeShorts,
      isTikTok,
      isOtherEmbed,
      overlayWidth,
      overlayHeight,
      overlayClipPath,
      onEmbedClick,
      youtubeStyle
    }
  }
}
</script>
