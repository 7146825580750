<template>
  <div>
    Hello there
  </div>
</template>

<script>
export default {
  name: 'QuestionForm',
  props: {
    question: {
      type: Object,
      default: {}
    }
  }
}
</script>
