<template>
  <form @submit.prevent="submitForm" class="space-y-4">
    <div>
      <label for="original_url" class="text-sm font-medium text-gray-700 flex">
        Video URL
      </label>
      <input
        type="text"
        id="original_url"
        v-model="formData.original_url"
        @input="debouncedSubmit"
        class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
      >

      <div class="flex text-xs text-gray-500 items-center mt-2">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 mr-1">
          <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
        </svg>

        <p class="italic">Only YouTube and YouTube Shorts URLs are supported.</p>
      </div>
    </div>
    <div v-if="block.embed.provider == 'youtube'" class="flex w-100 gap-4">
      <div class="grow">
        <label for="start" class="block text-sm font-medium text-gray-700">Start Time (in seconds)</label>
        <input
          type="text"
          id="start"
          v-model="formData.start"
          @input="debouncedSubmit"
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        >
      </div>
      <div class="grow">
        <label for="end" class="block text-sm font-medium text-gray-700">End Time (in seconds)</label>
        <input
          type="text"
          id="end"
          v-model="formData.end"
          @input="debouncedSubmit"
          class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        >
      </div>
    </div>
    <div v-if="errorMessage" class="text-red-500 text-sm">{{ errorMessage }}</div>
  </form>
</template>

<script>
import { ref } from 'vue'
import { useBlockUpdate } from '../../composables/useBlockUpdate'
import debounce from 'debounce'

export default {
  name: 'VideoForm',
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const { onBlockUpdate } = useBlockUpdate(props.block)

    const formData = ref({
      original_url: props.block.embed?.original_url || '',
      start: props.block.embed?.start || '',
      end: props.block.embed?.end || ''
    })

    const errorMessage = ref('')

    const validateForm = () => {
      if (parseFloat(formData.value.end) <= parseFloat(formData.value.start)) {
        errorMessage.value = 'End time must be greater than the start time, please update it to save.'
        return false
      }
      errorMessage.value = ''
      return true
    }

    const submitForm = () => {
      if (!validateForm()) return
      const embedData = JSON.stringify(formData.value)
      onBlockUpdate(embedData, 'embed_attributes')
    }

    const debouncedSubmit = debounce(submitForm, 2000)

    return {
      formData,
      debouncedSubmit,
      errorMessage
    }
  }
}
</script>
