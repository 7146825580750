<template>
  <div class="tiptap-toolbar" :data-shared="shared">
    <div v-if="currentEditor">
      <div class="button-group flex gap-1">
        <button @click="currentEditor.chain().focus().toggleBold().run()" :disabled="!currentEditor.can().chain().focus().toggleBold().run()" :class="{ 'is-active': currentEditor.isActive('bold') }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
            <path stroke-linejoin="round" d="M6.75 3.744h-.753v8.25h7.125a4.125 4.125 0 0 0 0-8.25H6.75Zm0 0v.38m0 16.122h6.747a4.5 4.5 0 0 0 0-9.001h-7.5v9h.753Zm0 0v-.37m0-15.751h6a3.75 3.75 0 1 1 0 7.5h-6m0-7.5v7.5m0 0v8.25m0-8.25h6.375a4.125 4.125 0 0 1 0 8.25H6.75m.747-15.38h4.875a3.375 3.375 0 0 1 0 6.75H7.497v-6.75Zm0 7.5h5.25a3.75 3.75 0 0 1 0 7.5h-5.25v-7.5Z" />
          </svg>
        </button>
        <button @click="currentEditor.chain().focus().toggleItalic().run()" :disabled="!currentEditor.can().chain().focus().toggleItalic().run()" :class="{ 'is-active': currentEditor.isActive('italic') }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M5.248 20.246H9.05m0 0h3.696m-3.696 0 5.893-16.502m0 0h-3.697m3.697 0h3.803" />
          </svg>
        </button>
        <button @click="currentEditor.chain().focus().toggleStrike().run()" :disabled="!currentEditor.can().chain().focus().toggleStrike().run()" :class="{ 'is-active': currentEditor.isActive('strike') }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 12a8.912 8.912 0 0 1-.318-.079c-1.585-.424-2.904-1.247-3.76-2.236-.873-1.009-1.265-2.19-.968-3.301.59-2.2 3.663-3.29 6.863-2.432A8.186 8.186 0 0 1 16.5 5.21M6.42 17.81c.857.99 2.176 1.812 3.761 2.237 3.2.858 6.274-.23 6.863-2.431.233-.868.044-1.779-.465-2.617M3.75 12h16.5" />
          </svg>

        </button>
        <button @click="currentEditor.chain().focus().setParagraph().run()" :class="{ 'is-active': currentEditor.isActive('paragraph') }">
          <div class="h-4">
            Paragraph
          </div>

        </button>
        <button @click="currentEditor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': currentEditor.isActive('heading', { level: 1 }) }">
          H1
        </button>
        <button @click="currentEditor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': currentEditor.isActive('heading', { level: 2 }) }">
          H2
        </button>
        <button @click="currentEditor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': currentEditor.isActive('heading', { level: 3 }) }">
          H3
        </button>
        <button @click="currentEditor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': currentEditor.isActive('heading', { level: 4 }) }">
          H4
        </button>
        <button @click="currentEditor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': currentEditor.isActive('bulletList') }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
          </svg>
        </button>
        <button @click="currentEditor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': currentEditor.isActive('orderedList') }">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.242 5.992h12m-12 6.003H20.24m-12 5.999h12M4.117 7.495v-3.75H2.99m1.125 3.75H2.99m1.125 0H5.24m-1.92 2.577a1.125 1.125 0 1 1 1.591 1.59l-1.83 1.83h2.16M2.99 15.745h1.125a1.125 1.125 0 0 1 0 2.25H3.74m0-.002h.375a1.125 1.125 0 0 1 0 2.25H2.99" />
          </svg>
        </button>
        <button @click="currentEditor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': currentEditor.isActive('blockquote') }">
          <svg fill="#000000" width="800px" height="800px" viewBox="0 0 36 36" version="1.1"  preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  class="h-4 w-4">
            <path d="M11.86,16.55a4.31,4.31,0,0,0-2.11.56,14.44,14.44,0,0,1,4.36-6,1.1,1.1,0,0,0-1.4-1.7c-4,3.25-5.78,7.75-5.78,10.54A5.08,5.08,0,0,0,10,24.58a4.4,4.4,0,0,0,1.88.44,4.24,4.24,0,1,0,0-8.47Z" class="clr-i-outline clr-i-outline-path-1"></path><path d="M23,16.55a4.29,4.29,0,0,0-2.11.56,14.5,14.5,0,0,1,4.35-6,1.1,1.1,0,1,0-1.39-1.7c-4,3.25-5.78,7.75-5.78,10.54a5.08,5.08,0,0,0,3,4.61A4.37,4.37,0,0,0,23,25a4.24,4.24,0,1,0,0-8.47Z" class="clr-i-outline clr-i-outline-path-2"></path>
            <rect x="0" y="0" width="36" height="36" fill-opacity="0"/>
          </svg>
        </button>
        <button @click="currentEditor.chain().focus().undo().run()" :disabled="!currentEditor.can().chain().focus().undo().run()">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4 rotate-90">
            <path stroke-linecap="round" stroke-linejoin="round" d="m15 15-6 6m0 0-6-6m6 6V9a6 6 0 0 1 12 0v3" />
          </svg>

        </button>
        <button @click="currentEditor.chain().focus().redo().run()" :disabled="!currentEditor.can().chain().focus().redo().run()">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-4 w-4" style="transform: rotate(270deg) scaleX(-1);">
            <path stroke-linecap="round" stroke-linejoin="round" d="m15 15-6 6m0 0-6-6m6 6V9a6 6 0 0 1 12 0v3" />
          </svg>
        </button>
      </div>
    </div>
    <div class="text-sm text-gray-600 ml-4 flex items-center">
      <SaveStatus />
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'
import SaveStatus from './SaveStatus.vue'

export default {
  name: 'Toolbar',
  components: {
    SaveStatus
  },
  props: {
    shared: {
      type: Boolean,
      default: true
    },
    editor: {
      type: Object,
      default: null
    }
  },
  setup(props) {
    const currentEditor = props.editor || inject('currentEditor')

    return {
      currentEditor
    }
  }
}
</script>

<style scoped>
  .tiptap-toolbar[data-shared="true"] {
    .button-group button {
      padding: 6px 12px;
      border-radius: 5px;
      background-color: white;
      font-size: 16px;

      &:hover {
        background-color: #f0f9ff;
        cursor: pointer;
      }

      &.is-active {
        font-weight: bold;
        background-color: #f0f9ff;
        color: #4338ca;
      }
    }
  }
</style>
