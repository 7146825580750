<template>
  <div class="h-[calc(100vh-130px)] flex flex-col">
    <Toolbar class="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8 py-3 flex justify-between h-12 z-10" />
    <div class="flex-grow flex overflow-hidden" >
      <div class="flex-grow transition-all duration-300 ease-in-out">
        <div class="reveal h-full">
          <div class="slides">
            <section v-if="parsedSlides.length === 0">Loading...</section>
            <template v-else>
              <Slide
                v-for="(slide, index) in parsedSlides"
                :key="index"
                :slide="slide"
                :index="index"
                ref="editorContainer"
              />
            </template>
          </div>
        </div>
      </div>
      <SidePanel
        :is-open="sidePanelOpen"
        :panel-content="sidePanelContent"
        :fixed="sidePanelFixed"
        @close="closeSidePanel"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, provide } from 'vue'
import { onClickOutside } from '@vueuse/core'
import Reveal from 'reveal.js'
import FsFx from 'reveal.js-fsfx'
import Slide from '../features/slides_editor/components/slides/Slide.vue'
import Toolbar from '../features/slides_editor/components/editor/Toolbar.vue'
import SidePanel from '../features/slides_editor/components/editor/SidePanel.vue'
import { useBlockHelpers, useSlideHelpers } from '../features/slides_editor/composables'

export default {
  name: 'SlidesEditor',
  props: {
    slides: {
      type: String,
      required: true,
      validator(value) {
        try {
          JSON.parse(value)
          return true
        } catch (e) {
          console.error('Invalid JSON:', e)
          return false
        }
      }
    }
  },
  components: {
    Slide,
    Toolbar,
    SidePanel
  },
  setup(props) {
    const parsedSlides = ref([])
    const currentEditor = ref(null)
    const saveStatusMessage = ref('')
    const isSaving = ref(false)
    const { deepMergeBlocks } = useBlockHelpers()
    const editorContainer = ref(null)
    const sidePanelOpen = ref(false)
    const sidePanelContent = ref({})
    const sidePanelFixed = ref(false)

    const reveal = ref(null)
    const { getConfig } = useSlideHelpers()

    const parseSlides = () => {
      try {
        parsedSlides.value = JSON.parse(props.slides)
      } catch (e) {
        console.error('Error parsing slides:', e)
      }
    }

    const updateSlides = (updatedSlideData) => {
      const index = parsedSlides.value.findIndex(slide => slide.id === updatedSlideData.id)
      if (index !== -1) {
        const currentSlide = parsedSlides.value[index]
        const mergedBlocks = deepMergeBlocks(currentSlide.blocks, updatedSlideData.blocks)
        parsedSlides.value[index] = {
          ...currentSlide,
          ...updatedSlideData,
          blocks: mergedBlocks
        }
      }
    }

    const openSidePanel = (content) => {
      sidePanelContent.value = content
      sidePanelOpen.value = true

      setTimeout(() => {
        reveal.value.layout()
      }, 300)
    }

    const closeSidePanel = () => {
      sidePanelOpen.value = false
      sidePanelContent.value = {}

      setTimeout(() => {
        reveal.value.layout()
      }, 300)
    }

    provide('openSidePanel', openSidePanel)
    provide('updateSlides', updateSlides)
    provide('parsedSlides', parsedSlides)
    provide('currentEditor', currentEditor)
    provide('saveStatusMessage', saveStatusMessage)
    provide('isSaving', isSaving)

    onMounted(() => {
      parseSlides()

      const onSlideChanged = (event) => {
        const slide = parsedSlides.value[reveal.value.getIndices().h]
        const config = getConfig(slide)

        if (config.side_panel.fixed) {
          openSidePanel(config.side_panel.content({ slide }))
          sidePanelFixed.value = true
        } else {
          closeSidePanel()
        }
      }

      reveal.value = new Reveal({
        plugins: [FsFx],
        slideNumber: 'c/t',
        hash: true,
        fsfx: {
          auto: {
            position: {
              left: '20px',
              top: '20px',
              right: false
            }
          }
        },
        width: 1280,
        height: 720
      })

      reveal.value.initialize().then(() => {
        onSlideChanged()
        reveal.value.on('slidechanged', onSlideChanged)
      })

      onClickOutside(editorContainer, (event) => {
        if (sidePanelFixed.value) {
          return
        }

        const sidePanel = document.querySelector('.side-panel')
        if (!event.target.closest('[data-editable]') && !sidePanel.contains(event.target)) {
          closeSidePanel()
        }
      })
    })

    return {
      parsedSlides,
      currentEditor,
      editorContainer,
      sidePanelOpen,
      sidePanelContent,
      sidePanelFixed,
      closeSidePanel,
    }
  }
}
</script>
