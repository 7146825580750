<template>
  <EditorContent v-if="editor" :editor="editor" class="tiptap-editable" :data-within-slide="withinSlide" />
</template>

<script>
import { EditorContent, useEditor } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import debounce from 'debounce'

import { onBeforeUnmount, onMounted, watch, inject } from 'vue'

export default {
  name: 'ContentEditor',
  props: {
    content: {
      type: String,
      required: true
    },
    withinSlide: {
      type: Boolean,
      default: true
    },
    onUpdate: {
      type: Function,
      required: true
    },
    forcedTag: {
      type: String,
      default: null
    }
  },
  components: {
    EditorContent
  },
  setup(props, { emit }) {
    const debouncedUpdate = debounce((html) => {
      props.onUpdate(html)
    }, 1000)

    const generateContent = (content, forcedTag) => {
      return forcedTag ? `<${forcedTag}>${content}</${forcedTag}>` : content
    }

    const editor = useEditor({
      extensions: [
        StarterKit
      ],
      content: generateContent(props.content, props.forcedTag),
      onUpdate: ({ editor }) => {
        debouncedUpdate(editor.getHTML())
      }
    })

    onMounted(() => {
      emit('editor-created', editor.value)
    })

    onBeforeUnmount(() => {
      editor.value.destroy()
    })

    return { editor }
  }
}
</script>

<style scoped>
  .tiptap-editable {
    border: 1px solid transparent;
    border-radius: 4px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      inset: -8px -16px;
      border: 3px solid transparent;
      border-radius: 8px;
      pointer-events: none;
      z-index: 20;
      transition: border-color 0.2s ease;
    }

    &:hover:not(:focus-within) {
      cursor: pointer;
    }

    &[data-within-slide="true"] {
      &:hover:not(:focus-within)::before,
      &:has(.tiptap.ProseMirror.ProseMirror-focused)::before {
        border-color: #8ee5fb;
      }
    }

    &[data-within-slide="false"] {
      @apply mt-1 block px-3 py-2 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
    }

  }
</style>
